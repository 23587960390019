import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';




@Injectable(
    {
      providedIn: 'root'
    }
  )


export class ContactPersonServices{
    constructor(private http: HttpClient) { }
    private baseUrl = `${environment.apiUrl}/tms/contactPerson`;


    createContactPerson (contactPerson: Object): Observable<Object> {
      return this.http.post(`${this.baseUrl}` + `/createContactPerson`, contactPerson, {observe: 'response'});
    }
    
    findContactPerson(companyCode,clientCode){
        return this.http.get(`${this.baseUrl}/findContactPerson/${companyCode}/${clientCode}`);
    }

    fetchContactPersons(companyCode,contactPerson){
      return this.http.get<[]>(`${this.baseUrl}/fetchContactPersons/${companyCode}/${contactPerson}`);
  }

    fetchContPersonById(id){
      return this.http.get(`${this.baseUrl}/fetchContPersonById/${id}`);
  }

  deleteById(id){
    return this.http.delete(`${this.baseUrl}/deleteById/${id}`, {observe: 'response'});
}
}