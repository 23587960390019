import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { Observable } from 'rxjs';
import { AssignRoles } from '../models/assign-roles';

@Injectable({
    providedIn : 'root'
})

export class AssignRolesService{

    constructor(private http : HttpClient){}

    private baseUrl = `${environment.apiUrl}/tms/assignRoles`;

    createAssignRole(assign : Object) : Observable<Object>{
        return this.http.post(`${this.baseUrl}/create`,assign,{observe : 'response'})
    }
    findByCompanyCode(companyCode){
        return this.http.get(`${this.baseUrl}/findByCompanyCode/`+companyCode)
    }
    findRole(companyCode,roleId){
        return this.http.get<AssignRoles>(`${this.baseUrl}/findRole/`+companyCode+'/'+roleId)
    }
    findByName(companyCode,name){
        return this.http.get<AssignRoles[]>(`${this.baseUrl}/findRoleByName/`+companyCode+'/'+name)
    }
}