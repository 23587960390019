import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { environment } from "../../environments/environment";
import { Role } from "../models/role";
import { catchError } from "rxjs/operators";


@Injectable(
  {
    providedIn: 'root'
  }
)
export class RoleService
{
  constructor(private http: HttpClient) { }
  private baseUrl = `${environment.apiUrl}/tms/role`;

  createRole(role: Object): Observable<Object> {
    return this.http.post(`${this.baseUrl}` + `/create`, role,{observe :'response'});
  }
  updateRole(role: Object): Observable<Object> {
    return this.http.put(`${this.baseUrl}` + `/update`, role,{observe :'response'});
  }
  listRole(companyId)
  {
    return this.http.get<Role[]>(`${environment.apiUrl}/tms/role/findAll/${companyId}`) ;
   
 }
 

 searchRoleById(term) {
  return this.http.get<Role[]>(`${environment.apiUrl}/tms/role/find/` + term) //.map(res => res)
}
searchRole(companyCode,roleName) {
  return this.http.get(`${environment.apiUrl}/tms/role/searchRole/${companyCode}/${roleName}`) //.map(res => res)
}
 handleError(error: HttpErrorResponse){
  console.log(error.status);
  if(error.status==200)
  {
    console.log("successfully");
  }
       return throwError(error);
  }
}