import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ClientTaskManager } from '../models/clientTaskManager';

@Injectable({
    providedIn : 'root'
})

export class ClientTaskManagerService{

    constructor(private http : HttpClient){}
    private reqHeader = new HttpHeaders({
        "Access-Control-Allow-Origin":"*",
        "Access-Control-Allow-Methods":"GET, POST",
        "Content-Type":"application/json",
    });
    
    private baseUrl = `${environment.apiUrl}/tms/clientTaskManager`

    createTask(clientTask : Object) : Observable<Object>{
        return this.http.post(`${this.baseUrl}/create`,clientTask,{observe:'response'})
    }
    createTaskWithFile(clientTask : Object) : Observable<Object>{
        return this.http.post(`${this.baseUrl}/createWithFile`,clientTask,{observe:'response'})
    }
    createLoginCall(clientTask : Object) : Observable<Object>{
        return this.http.post(`${environment.apiUrl}/tms/loginCall/create`,clientTask,{observe:'response'})
    }
    multipleSearch(filter){
        return this.http.post<any>(`${this.baseUrl}/multisearch`,filter)
    }
    multipleSearchForCall(filter){
        return this.http.post<any>(`${environment.apiUrl}/tms/loginCall/multisearch`,filter)
    }
    genrateExcel(list){
        return this.http.post(`${this.baseUrl}/multisearchExcel`,list,{headers:this.reqHeader})
    }
    remainderSearch(filter){
        return this.http.post(`${this.baseUrl}/remainderSearch`,filter)
    }
    sendMails(list){
        return this.http.post(`${this.baseUrl}/sendMails`,list,{observe:'response'})
    }
    searchById(id){
        return this.http.get<ClientTaskManager>(`${this.baseUrl}/findById/`+id)
    }
    searchByCallId(id){
        return this.http.get<ClientTaskManager>(`${environment.apiUrl}/tms/loginCall/findById/`+id)
    }
    searchHandlerHistory(companyCode,parentTaskNo){
        //findSurveyorHistory
        return this.http.get(`${this.baseUrl}/findHistory/`+companyCode+'/'+parentTaskNo)
    }
    searchHistory(companyCode,parentTaskNo){
        //findHistory
        return this.http.get(`${this.baseUrl}/findSurveyorHistory/`+companyCode+'/'+parentTaskNo)
    }
    searchStage(companyCode,stage){
        return this.http.get(`${environment.apiUrl}/tms/stage/findStage/`+companyCode+'/'+stage)
    }
    searchSurveyorStage(companyCode,stage){
        return this.http.get(`${environment.apiUrl}/tms/stage/findSurveyorStage/`+companyCode+'/'+stage)
    }
    searchSurveyorName(companyCode,name){
        return this.http.get(`${environment.apiUrl}/tms/surveyor/findName/`+companyCode+'/'+name)
    }
    searchInsuredName(companyCode,name){
        return this.http.get(`${environment.apiUrl}/tms/insure/findInsureName/`+companyCode+'/'+name)
    }
    getImageById(id){
        return this.http.get(`${environment.apiUrl}/tms/fileupload/downloadFile/`+id, {  responseType: 'blob' })//: {  responseType: 'application/octet-stream' }Observable<HttpResponse<Blob>>,{ responseType: ResponseContentType.Blob }
    }
    searchRemindersHistory(companyCode,taskNo,parentTaskNoTo){
        return this.http.get(`${this.baseUrl}/fetchReminders/`+companyCode+'/'+taskNo+'/'+parentTaskNoTo)
    }

    uploadCsv(file: Object) {
        var obj = this.http.post(`${environment.apiUrl}/tms/loginCall/uploadcsv`, file, { observe: 'response' });
        return obj;
    }
 
}