import { Currency } from './currency';

export class SalesInvoice {

  id: number
  companyCode
  branchCode

  invoiceNum
  invoiceDate
  kindAttention
  claimRefNo
  dateTo
  dateFrom
  igst
  cgst
  sgst

  serviceType
  surveyLocation
  contactPerson

  paymentDueDate
  itemDescription
  invoiceTypeId
  docTypeId
  totalTaxable
  custId
  custName
  itemCode
  caseId
  hsnCode
  unitPrice
  uom
  custGst
  fiscalYear
  postingDate
  postingPeriod
  stateCodeS
  stateNameS
  despatchDest
  delevryChallan
  reverseCharges
  stateCodeR
  stateNameR
  placeOfSupply
  invStage
  transactionType
  glCodeRevenue
  glNameRevenue
  glCodeDebtors
  glNameDebtors
  amountInWords
  exemptionTax
  salesPerson
  advAdjustment
  gstSchemeCode
  docType
  reasonAdjust
  internalSalesOrder
  purchaseOrderRef
  shipingMethod
  ewayBillNo
  ewayBillDate
  section
  quantity
  gstRate
  quotationRefNo
  billto
  discount
  forexAmount;
  gstSettlementFlag;
  profitCenter;
  subProfitCenter;
  portCode;
  returnPeriod;
  dueDate;
  jurisdiction;
  termsOfDelivery;
  disclosureTop;
  declarationBotom;
  declarationInterest;
  roundingOff;
  gstPayableOnReverseCharge;
  orderDate;

  shipTo
  invId: number
  currency
  currencyId: number
  curr_rate;
  taxableAmount
  igstAmount
  cgstAmount
  sgstAmount
  cessAmount
  invoiceTotal
  createdBy;
  createdDate: Date
  updatedBy;
  updatedDate: Date
  payementStatus;
  invoiceLines: any
  invoiceVoucherNo: number;
  invLineNo: number
  custPinCode;
  custCity;
  invoiceType

  assignedTo
  referenceNumber

  roleId;
  loginPersonName;

  insuredName
  eInvoiceType // stores B2B , B2C Invoice type value
  eInvType // only for e-invoice Search Purpose
  rateType


  crnInvRefNumber;
  lineTotalAmount


  /************************* */
   ackDate;
	 ackNumber;
	 ackNoStr;
	 
	 eWayBillDate;
	 eWayBillStatus;
	 eWayBillValidTill;

	 invoicePdfUrl;
	 irn;
	 irnStatus;
	 irp;
	 remarks;
  
	 signedInvoice;


	 signedQrCode;
	 signedQrCodeImageUrl;

	 eInvoiceStatus;
	 message;

	 uuid;
}