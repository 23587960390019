import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable, from } from "rxjs";
import { environment } from "../../environments/environment";
import { Taskgroupmaster } from "../models/taskgroupmaster";
import { TaskSubGroupMaster } from "../models/tasksubgroupmaster";



@Injectable(
  {
    providedIn: 'root'
  }
)
export class TaskSubGroupMasterService
{
  constructor(private http: HttpClient) { }
  
  createTaskSubGroupMaster (subtaskmaster: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/tasksubgroupmaster` + `/create`, subtaskmaster, {observe: 'response'});
  }
  updateTaskSubGroupMaster (subtaskmaster: Object): Observable<Object> {
    return this.http.put(`${environment.apiUrl}/tms/tasksubgroupmaster` + `/update`, subtaskmaster, {observe: 'response'});
  }
  listTaskGroup(companyId){
    return this.http.get<Taskgroupmaster[]>(`${environment.apiUrl}/tms/taskgroupmaster/findAll/${companyId}`);
  }
  listSubTaskGroup(companyId){
    return this.http.get<TaskSubGroupMaster[]>(`${environment.apiUrl}/tms/tasksubgroupmaster/findAll/${companyId}`);
  }
  searchSubTaskGroup(companyCode,subTaskName){
    return this.http.get(`${environment.apiUrl}/tms/tasksubgroupmaster/searchSubTaskGroup/${companyCode}/${subTaskName}`);
  }
  searchSubTaskByTaskId(taskId){
    return this.http.get<TaskSubGroupMaster[]>(`${environment.apiUrl}/tms/tasksubgroupmaster/findgroup/${taskId}`);
  }
}