import { HttpClient } from "@angular/common/http";
import 'rxjs/add/operator/toPromise';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { TaskManager } from "./taskmanager";
import { CompanyBrnch } from "../../../models/companybrnch";
import { environment } from "../../../../environments/environment";
import { Taskgroupmaster } from "../../../models/taskgroupmaster";
import { TaskSubGroupMaster } from "../../../models/tasksubgroupmaster";
import { Department } from "../../../models/department";


@Injectable(
  {
    providedIn: 'root'
  }
)
export class TaskManagerService {
  constructor(private http: HttpClient) { }



  searchBranchCode(term) {
    return this.http.get<CompanyBrnch[]>(`${environment.apiUrl}/tms/companybranch/find/` + term) //.map(res => res)
  }

  searchBranchName(term) {
    return this.http.get<CompanyBrnch[]>(`${environment.apiUrl}/tms/companybranch/find1/` + term) //.map(res => res)
  }

  searchTaskGroup(term) {
    return this.http.get<Taskgroupmaster[]>(`${environment.apiUrl}/tms/taskgroupmaster/find1/` + `${term}`) //.map(res => res)
  }

  searchTaskSubGroup(term) {
    return this.http.get<TaskSubGroupMaster[]>(`${environment.apiUrl}/tms/tasksubgroupmaster/findgroup/` + `${term}`) //.map(res => res)
  }
    searchTaskGroupById(term) {
    return this.http.get(`${environment.apiUrl}/tms/taskgroupmaster/findById/` + term) //.map(res => res)
  }
  searchTaskSubGroupById(term) {
    return this.http.get(`${environment.apiUrl}/tms/tasksubgroupmaster/findById/` + term) //.map(res => res)
  }

  searchTaskNoIFExist(taskNo,companyCode,year) {
    return this.http.get(`${environment.apiUrl}/tms/taskmanager/taskno-company/`+ `${taskNo}/`+ `${companyCode}/`+ `${year}`) //.map(res => res)
  }

  createTaskManager(taskMangerBean: Object): Observable<Object> {

    return this.http.post(`${environment.apiUrl}/tms/taskmanager` + `/createWithFile`, taskMangerBean ,{observe: 'response'});
   // return this.http.post(`${environment.apiUrl}/tms/taskmanager` + `/create`, taskmanager ,{observe: 'response'});

  }


  updateTaskManager(taskMangerBean: Object): Observable<Object> {
    return this.http.post(`${environment.apiUrl}/tms/taskmanager` + `/update`, taskMangerBean ,{observe: 'response'});
  }

  allTaskManager(id: number): Observable<Object> {
    return this.http.get(`${environment.apiUrl}/${id}`);
  }

  listTaskManager() {
    return this.http.get<TaskManager[]>(`${environment.apiUrl}`);

  }

  createMakerdata(maker: Object): Observable<Object> {

    return this.http.post(`${environment.apiUrl}` + `/create`, maker);

  }
  getImageById(id :number){
   
    return this.http.get(`${environment.apiUrl}/tms/taskImage/downloadfile/` + `${id}`, {  responseType: 'blob' })//: {  responseType: 'application/octet-stream' }Observable<HttpResponse<Blob>>,{ responseType: ResponseContentType.Blob }
  }
  deleteImageById(id :number){
    return this.http.get(`${environment.apiUrl}/tms/taskImage/deletefile/` + `${id}`)
  }


}